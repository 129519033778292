import React, { useState } from 'react'
import {Select, MenuItem, Grid, Typography} from '@mui/material'

const SelectWord = ( {handleParentState, data, country, index}) => {
    console.log(country,'key')
    console.log(data[country].length,"length")
    console.log(index)
    
    


    const [entryIndex, setIndex] = useState()
    const [name, setName] = useState()
    const [currentSelect, setCurrentSelected] = useState()

    const wordArray = data[country][index].title.split(' ')
    console.log(wordArray, "ypur word array")
    const sentence = wordArray.join(' ')
    const handleCurrentSelectChange = (e) => {
        handleParentState(country,index,e.target.value,)
        setCurrentSelected(e.target.value)
    }

    console.log(currentSelect)

  return (
    <>
    <Grid item>
        <p>{sentence}</p>
        <Select onChange={handleCurrentSelectChange} value={currentSelect} sx={{width:"250px"}} >
            {wordArray.map((word) => (
                
                <MenuItem value = {word}>{word}</MenuItem>
            ))}
        </Select>
    </Grid>
    </>
  )
}

export default SelectWord