import {
  Button,
  Grid,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Box,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useRef, useEffect } from "react";
import countries from "../countries";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import json2md from "json2md";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const [country, setCountry] = useState(null);
  const [fetchedCountry, setFetchedCountry] = useState(null);
  const [period, setPeriod] = useState("7d");
  const [maxResults, setMaxResults] = useState(10);
  const [showResults, setShowResults] = useState(true);
  const [checked, setChecked] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchNewsBtn = useRef(null);
  const genNewsBtn = useRef(null);
  const naviagte = useNavigate()

  const onGenerateReportClick = () => {
    
    const reducedItems = Object.keys(checked).reduce(
      (result, item) => {
        const filteredItems = checked[item].filter(
          (item) => item.checked
        );

        result[item] = filteredItems;
        return result;
      },
      {}
    );

    let md = [
      { h1: "تقرير رصد أخبار عن " },
      {
        h2: "(" + Object.keys(reducedItems).join(" , ") + ")",
      },
    ];

    Object.keys(reducedItems).map((item, index) => {
      if (reducedItems[item].length > 0) {
        md.push({ h3: item });
      }
      reducedItems[item].map((news, index) => {
        md.push({
          h5: news.title
            .replace(news.publisher, "")
            .replace(" -", ""),
        });

        md.push({
          p: news.publisher,
        });
      });
    });

    md = json2md(md);
    const element = document.createElement("a");
    const file = new Blob([md], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "news.md";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const onNextButtonClick = () => {
    const keys = Object.keys(checked)
    const filterResults = () => {
      const filteredResults = {}
    for (const key in checked) {
      if (Object.hasOwnProperty.call(checked, key)) {
        
        const filteredArray = checked[key].filter((entry) => {
          return entry.checked === true
        })
        filteredResults[key] = filteredArray
        console.log(filteredResults)
        
        
      }
    }
    return filteredResults
  }
  const results = filterResults()
  naviagte("/export",{state:{checked:results}})
  }
    //naviagte("/export",{state:{checked:checked}})
  


  const client = wretch().addon(QueryStringAddon);

  const fetchNews = async () => {
    setLoading(true);
    await client
      .url(process.env.REACT_APP_API_URL + "/news")
      .query({
        country: country.toLowerCase().trim(),
        period,
        max_results: maxResults,
        multi: country.split(",").length > 1 ? true : false,
      })
      .get()
      .json()
      .then((data) => {
        data.map((item, index) => {
          setChecked((arr) => ({
            ...arr,
            [item.country]: item.news.map((item, index) => ({
              title: item.title,
              publisher: item.publisher.title,
              published_date: new Date(
                item["published date"]
              ).toLocaleDateString(),
              checked: false,
              url: item.url,
            })),
          }));
        });
        setShowResults(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    setChecked((arr) => ({
      ...arr,
      [value]: arr[value].map((item, index) => ({
        ...item,
        checked: item.title === name ? checked : item.checked,
      })),
    }));
  };
  const handleCheckBoxChange = (e) => {
    
  }

  useEffect(() => {
    console.log("checked: ", checked);
  }, [checked]);

  return (
    <Paper
      elevation={3}
      sx={{
        height: "100vh",
        padding: "1rem",
        backgroundColor: "#1B2430",
        width: "100vw",
        color: "black",
      }}
    >
      <Typography variant="h4" align="center" color="white">
        Get Articles
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "1rem", alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          align="center"
          sx={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            multiple
            id="country-select"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
            }}
            onChange={(event, value) => {
              value.map((item, index) => {
                if (country == null) {
                  setCountry(item.code);
                } else {
                  setCountry(country + "," + item.code);
                }
              });
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} align="center">
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            placeholder="Period"
            defaultValue="7"
            onChange={(event) => {
              setPeriod(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={6} align="center">
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            placeholder="Max Results"
            defaultValue="10"
            onChange={(event) => {
              setMaxResults(event.target.value);
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          hidden={showResults}
          sx={{
            marginTop: "1rem",
          }}
        >
          <FormControl
            component="fieldset"
            sx={{
              backgroundColor: "white",
              marginTop: "1rem",
              borderRadius: "15px.",
            }}
            fullWidth
          >
            <FormLabel
              component="legend"
              sx={{
                color: "black",
                marginTop: "1rem",
              }}
            >
              Choose the news categories
            </FormLabel>
            <FormGroup>
              {Object.keys(checked).map((item, index) => {
                return (
                  <>
                    <h1>{item}</h1>
                    {checked[item].map((news, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={news.checked}
                            onChange={handleChange}
                            name={news.title}
                            value={item}
                          />
                        }
                        label={news.title + " - " + news.published_date}
                      />
                    ))}
                  </>
                );
              })}
              {/* {checked.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={handleChange}
                        name={item.title}
                      />
                    }
                    label={
                      item.title +
                      " - " +
                      item.publisher +
                      " - " +
                      item.published_date
                    }
                  />
                );
              })} */}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          align="center"
          sx={{
            marginTop: "1rem",
          }}
        >
          {loading ? (
            <>
              <LoadingButton
                loading
                loadingPosition="center"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#51557E",
                  "&:hover": {
                    backgroundColor: "#51557E",
                  },
                  color: "white",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "white",
                  },
                }}
              >
                Fetching News
              </LoadingButton>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#51557E",
                  "&:hover": {
                    backgroundColor: "#51557E",
                  },
                  display: showResults ? "block" : "none",
                }}
                onClick={async () => {
                  setShowResults([]);
                  await fetchNews();
                }}
                ref={fetchNewsBtn}
              >
                Fetch News
              </Button>

              <Button
                onClick={onNextButtonClick}
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#51557E",
                  "&:hover": {
                    backgroundColor: "#51557E",
                  },
                  display: showResults ? "none" : "block",
                }}
                ref={genNewsBtn}
                
                
              >
                
                Next
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
              }


export default Home;
