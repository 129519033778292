import { BrowserRouter, Routes,Route } from "react-router-dom";
import Home from "./components/Home";
import ExportPage from "./components/ExportPage";


const AppRouter = () => {
    return(
    <BrowserRouter>
        <Routes>
            <Route path="/" element = {<Home />} />
            <Route path="/export" element = {<ExportPage />} />
        </Routes>
    </BrowserRouter>
    )
}

export default AppRouter