const countries = [
        {
            "code": "AR",
            "label": "Argentina",
            "phone": "54"
        },
        {
            "code": "AU",
            "label": "Australia",
            "phone": "61"
        },
        {
            "code": "AT",
            "label": "Austria",
            "phone": "43"
        },
        {
            "code": "BD",
            "label": "Bangladesh",
            "phone": "880"
        },
        {
            "code": "BE",
            "label": "Belgium",
            "phone": "32"
        },
        {
            "code": "BW",
            "label": "Botswana",
            "phone": "267"
        },
        {
            "code": "BR",
            "label": "Brazil",
            "phone": "55"
        },
        {
            "code": "BG",
            "label": "Bulgaria",
            "phone": "359"
        },
        {
            "code": "CA",
            "label": "Canada",
            "phone": "1"
        },
        {
            "code": "CL",
            "label": "Chile",
            "phone": "56"
        },
        {
            "code": "CN",
            "label": "China",
            "phone": "86"
        },
        {
            "code": "CO",
            "label": "Colombia",
            "phone": "57"
        },
        {
            "code": "CU",
            "label": "Cuba",
            "phone": "53"
        },
        {
            "code": "CZ",
            "label": "Czech Republic",
            "phone": "420"
        },
        {
            "code": "EG",
            "label": "Egypt",
            "phone": "20"
        },
        {
            "code": "ET",
            "label": "Ethiopia",
            "phone": "251"
        },
        {
            "code": "FR",
            "label": "France",
            "phone": "33"
        },
        {
            "code": "DE",
            "label": "Germany",
            "phone": "49"
        },
        {
            "code": "GH",
            "label": "Ghana",
            "phone": "233"
        },
        {
            "code": "GR",
            "label": "Greece",
            "phone": "30"
        },
        {
            "code": "HK",
            "label": "Hong Kong",
            "phone": "852"
        },
        {
            "code": "HU",
            "label": "Hungary",
            "phone": "36"
        },
        {
            "code": "IN",
            "label": "India",
            "phone": "91"
        },
        {
            "code": "ID",
            "label": "Indonesia",
            "phone": "62"
        },
        {
            "code": "IL",
            "label": "Israel",
            "phone": "972"
        },
        {
            "code": "IT",
            "label": "Italy",
            "phone": "39"
        },
        {
            "code": "JP",
            "label": "Japan",
            "phone": "81"
        },
        {
            "code": "KE",
            "label": "Kenya",
            "phone": "254"
        },
        {
            "code": "LV",
            "label": "Latvia",
            "phone": "371"
        },
        {
            "code": "LB",
            "label": "Lebanon",
            "phone": "961"
        },
        {
            "code": "LT",
            "label": "Lithuania",
            "phone": "370"
        },
        {
            "code": "MY",
            "label": "Malaysia",
            "phone": "60"
        },
        {
            "code": "MX",
            "label": "Mexico",
            "phone": "52"
        },
        {
            "code": "MA",
            "label": "Morocco",
            "phone": "212"
        },
        {
            "code": "NA",
            "label": "Namibia",
            "phone": "264"
        },
        {
            "code": "NL",
            "label": "Netherlands",
            "phone": "31"
        },
        {
            "code": "NZ",
            "label": "New Zealand",
            "phone": "64"
        },
        {
            "code": "NG",
            "label": "Nigeria",
            "phone": "234"
        },
        {
            "code": "NO",
            "label": "Norway",
            "phone": "47"
        },
        {
            "code": "PK",
            "label": "Pakistan",
            "phone": "92"
        },
        {
            "code": "PE",
            "label": "Peru",
            "phone": "51"
        },
        {
            "code": "PH",
            "label": "Philippines",
            "phone": "63"
        },
        {
            "code": "PL",
            "label": "Poland",
            "phone": "48"
        },
        {
            "code": "PT",
            "label": "Portugal",
            "phone": "351"
        },
        {
            "code": "RO",
            "label": "Romania",
            "phone": "40"
        },
        {
            "code": "RU",
            "label": "Russia",
            "phone": "7"
        },
        {
            "code": "SA",
            "label": "Saudi Arabia",
            "phone": "966"
        },
        {
            "code": "SN",
            "label": "Senegal",
            "phone": "221"
        },
        {
            "code": "RS",
            "label": "Serbia",
            "phone": "381"
        },
        {
            "code": "SG",
            "label": "Singapore",
            "phone": "65"
        },
        {
            "code": "SK",
            "label": "Slovakia",
            "phone": "421"
        },
        {
            "code": "SI",
            "label": "Slovenia",
            "phone": "386"
        },
        {
            "code": "ZA",
            "label": "South Africa",
            "phone": "27"
        },
        {
            "code": "SE",
            "label": "Sweden",
            "phone": "46"
        },
        {
            "code": "CH",
            "label": "Switzerland",
            "phone": "41"
        },
        {
            "code": "TW",
            "label": "Taiwan",
            "phone": "886"
        },
        {
            "code": "TZ",
            "label": "Tanzania",
            "phone": "255"
        },
        {
            "code": "TH",
            "label": "Thailand",
            "phone": "66"
        },
        {
            "code": "TR",
            "label": "Turkey",
            "phone": "90"
        },
        {
            "code": "UG",
            "label": "Uganda",
            "phone": "256"
        },
        {
            "code": "UA",
            "label": "Ukraine",
            "phone": "380"
        },
        {
            "code": "AE",
            "label": "United Arab Emirates",
            "phone": "971"
        },
        {
            "code": "GB",
            "label": "United Kingdom",
            "phone": "44"
        },
        {
            "code": "US",
            "label": "United States",
            "phone": "1"
        },
        {
            "code": "VE",
            "label": "Venezuela",
            "phone": "58"
        },
        {
            "code": "VN",
            "label": "Vietnam",
            "phone": "84"
        },
        {
            "code": "ZW",
            "label": "Zimbabwe",
            "phone": "263"
        }
];

export default countries;
