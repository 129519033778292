import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PdfDocument from "./PdfDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Grid, Typography } from "@mui/material";
import SelectWord from "./SelectWord";

const ExportPage = () => {
  const location = useLocation();
  const { checked } = location.state;
  const [selected, setSelected] = useState(checked);

  const keys = Object.keys(selected);

  const handleParentState = (country, index, highlightedWord) => {
    const countryKey = keys[keys.indexOf(country)];

    const newState = {
      ...selected[`${country}`][index],
      highlightedWord: highlightedWord,
    };

    const countryNews = selected[`${country}`];
    countryNews[index] = newState;
    const stateCopy = { ...selected, [countryKey]: countryNews };
    setSelected(stateCopy);
  };

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"flex-end"}
      gap={"24px"}
    >
      <Grid item>
        <Typography sx={{ fontSize: "48px" }}>Report Generator</Typography>
      </Grid>
      {keys.map((key) =>
        selected[key].map((entry, index) => (
          <SelectWord
            handleParentState={handleParentState}
            country={key}
            index={index}
            data={selected}
          />
        ))
      )}
      <PDFDownloadLink
        style={{ fontSize: 24 }}
        fileName="test"
        document={<PdfDocument checked={selected} />}
      >
        Generate PDF
      </PDFDownloadLink>
    </Grid>
  );
};

export default ExportPage;
