import React from 'react';
// import {pdfjs} from 'react-pdf'
import { Document, Page, Text, View, StyleSheet, Link, Font, Image } from '@react-pdf/renderer';
import HeaderTwo from '../HeaderTwo.png'
import HeaderOne from '../HeaderOne.png'
import Globe from '../globe.png'
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

Font.register({  family: 'Cairo', src: 'https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPO_9CTVsVJKxTs.ttf' });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: "Cairo",
    textAlign:"right",
    paddingBottom:"50px",
    paddingTop:"20px"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    paddingBottom: 30,
  },
  
});

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// const sample = {
//     "title": "صورة نائبة رئيس الأرجنتين المثيرة في مكتبها تتصدر الترند - العربية",
//     "publisher": "العربية",
//     "published_date": "1/31/2024",
//     "checked": true,
//     "highlightedWord": "الأرجنتين",
//     "url": "https://news.google.com/rss/articles/CBMi1AJodHRwczovL3d3dy5hbGFyYWJpeWEubmV0L3NvY2lhbC1tZWRpYS8yMDI0LzAxLzMxLyVEOCVCNSVEOSU4OCVEOCVCMSVEOCVBOS0lRDklODYlRDglQTclRDglQTYlRDglQTglRDglQTktJUQ4JUIxJUQ4JUE2JUQ5JThBJUQ4JUIzLSVEOCVBNyVEOSU4NCVEOCVBMyVEOCVCMSVEOCVBQyVEOSU4NiVEOCVBQSVEOSU4QSVEOSU4Ni0lRDglQTclRDklODQlRDklODUlRDglQUIlRDklOEElRDglQjElRDglQTktJUQ5JTgxJUQ5JThBLSVEOSU4NSVEOSU4MyVEOCVBQSVEOCVBOCVEOSU4NyVEOCVBNy0lRDglQUElRDglQjElRDklODMlRDglQTgtJUQ4JUE3JUQ5JTg0JUQ4JUFBJUQ4JUIxJUQ5JTg2JUQ4JUFG0gEA?oc=5&hl=en-US&gl=US&ceid=US:en"
// }

const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/",
};



const PdfDocument = ({checked}) => {

    const date =  new Date()
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()
    const currentDate = `${year}/${month}/${day}`

    const keys = Object.keys(checked)


    

    const processLink = (link) => {
    
        const word = link.highlightedWord
        let linkFound = false
    
        const textBeforeLink = []
        const result = []
        const textAfterLink = []
    
        const words = link.title.split(" ")
    
    
        words.forEach(element => {
            if (element !== word && !linkFound) {
                textBeforeLink.push(element)
            }
            else if (element === word) {
                linkFound = true
                result.push(element)
                // result.push(<Link>{element}</Link>)
            }
            else {
                textAfterLink.push(element)
            }
        })
    
        return [textBeforeLink.join(" "), result.join(" "), textAfterLink.join(" "),link.url]
    }

    const processData = (data) => {
        const articleLinks = {}
        for (const key in data) {
            
            if (data.hasOwnProperty(key)) {
                const element = data[key].map((article) => processLink(article))
                articleLinks[key] = element
            }
        }
        console.log(articleLinks, "final article links")
        return articleLinks
    }


    const finalData = processData(checked)

    const processTitle = (keys) => {
        const title = '' 
        let middle = ''
        for (let i = 0; i<= keys.length; i++){
            if (i === keys.length-1){
                const finalTitle = title + middle + keys[i] +" "
                return finalTitle
            }
            if (i === 0){
            middle =   keys[i] + ', '
            }
             
        }
    }
    console.log(keys)
    console.log(processTitle(keys))

  return (
    <Document options={options} style={{fontFamily:'Rubik'}} >
      <Page  size="A4" style={styles.page}>
        <View style={{display:"flex", flexDirection:"column", gap:"100px", justifyContent:"center"}}>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
                <View  style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <Image style={{width:"100px", height:"100px"}} src={HeaderTwo}></Image>
                <Image style={{width:"100px", height:"100px", paddingTop:"20px"}} src={HeaderOne}></Image>
                </View>
                <Text style={{paddingRight:"10px"}}>{currentDate}</Text>
            </View>
            <View style={{display:"flex",justifyContent:"center", flexDirection:"column", alignItems:"center", gap:"25px"}}>
                <View style={{display:"flex",justifyContent:"center", flexDirection:"column", alignItems:"center", gap:"10px"}}>
                <Text style={{fontSize:"37px"}} >تقرير رصد أخبار عن </Text>
                <Text>({processTitle(keys)})</Text>
                </View>
                <View style={{justifyContent:"center"}}>
                    <Image style={{width:"300px",height:"150px"}} src={Globe}></Image>
                </View>
                <Text>عضو المنتدى الدولي</Text>
                <View style={{display:"flex",flexDirection:"row", textAlign:"center", justifyContent:"flex-end", width:"100%",paddingRight:"25px"}} >
                    <Text>({processTitle(keys)})</Text>
                    <Text >تقرير رصد أخبار عن </Text>
                </View>
            </View>
        </View>
        <View break style={styles.section} >
            <View style={{flexDirection:"row", textAlign:"center", justifyContent:"center"}}>
            <Text>({processTitle(keys)})</Text>
          <Text >تقرير رصد أخبار عن </Text>
          
          </View>
          {
            keys.map((key) => 
            <View 
            style={{ flexDirection: "column", fontWeight:"bold", marginBottom: 4, gap:"5px", marginTop:"20px"}}
            >
                <Text  style={{fontWeight:"bold", textAlign:"right"}}>: {key} •</Text>
                {finalData[key].map((dataLinkArray) => 
                <View style={{ flexDirection: "row",justifyContent:"flex-end", fontSize:"10px", fontWeight: "400", marginBottom: 4, paddingTop:"20px"}}>
                    
                    <Text style={{width:"95%"}}>
                        {dataLinkArray[2]}
                        {<Link src={dataLinkArray[3]}>{' ' + dataLinkArray[1] +' '}</Link>}
                        {' '+ dataLinkArray[0]}
                    </Text>
                    <Text style={{width:"5%"}} >-</Text>
                </View>
                )}
            </View>)
            
          }
        </View>
        <View fixed style={{marginBottom:"10px",marginRight:"75px", display:"flex", flexDirection:"row", gap:"25px", justifyContent:"flex-end", bottom:0, position:"absolute" }} >
            <View style={{backgroundColor:"#bf9000", width:"100%", paddingRight:"20px"}}>
            <Text style={{color:"white"}}  >تنسيقية شباب الأحزاب والسياسيين |     المنتدي الدولي</Text>
            </View>
            <View style={{backgroundColor:"white"}}>
            <Text render={({pageNumber}) =>(
                 `${pageNumber}`
            )} />
            </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument